import {
    ADD_ENTITLEMENTS_ERROR,
    ADD_ENTITLEMENTS_SUCCESS,
    BOARDSEARCH_ERROR,
    BOARDSEARCH_SUCCESS,
    CLEAR_BOARD_SEARCH_RESULTS,
    CLEAR_ADD_ENTITLEMENTS_SUCCESS,
    CLEAR_ENTITLEMENTS_RESULTS,
    GET_CHIP_LIST_ERROR,
    GET_CHIP_LIST_SUCCESS,
    GET_CURRENT_ENTITLEMENTS_LIST_ERROR,
    GET_CURRENT_ENTITLEMENTS_LIST_SUCCESS,
    GET_ENTITLEMENTS_LIST_ERROR,
    GET_ENTITLEMENTS_LIST_SUCCESS,
    GET_PO_ERROR,
    GET_PO_SUCCESS,
    GET_PBULD_LIST_ERROR,
    GET_PBULD_LIST_SUCCESS,
    GET_PRODUCT_LIST_ERROR,
    GET_PRODUCT_LIST_SUCCESS,
    GET_REVISION_LIST_ERROR,
    GET_REVISION_LIST_SUCCESS,
    LOGIN_ERROR,
    LOGIN_SUCCESS,
    LOGOUT,
    PREFETCH_PO_LIST_ERROR,
    PREFETCH_PO_LIST_SUCCESS,
    SAVE_LOT_NUMBER_ERROR,
    SAVE_LOT_NUMBER_SUCCESS,
    CLEAR_LOT_NUMBER_RESULTS,
    SET_IS_LOADING,
    UPLOAD_BUILD_ERROR,
    UPLOAD_BUILD_SUCCESS,
} from '../constants/action-types'

// TODO: Consider a function that generates a reducer,
// or at least pull all reducers out in their own file.

// TODO: Consolidate interfaces.

interface StateShape {
    addEntitlementsError: string
    addEntitlementsSuccess: string
    boardResult: any
    boardSearchError: string
    chipTypes: string[]
    entitlements: object[]
    getEntitlementsListError: string
    getCurrentEntitlementsListError: string
    currentEntitlements: string[]
    getPOError: string,
    getProductListError: string
    getRevisionListError: string
    isLoading: boolean
    loggedIn: boolean
    loginError: string
    products: object[]
    pbuldList: object[]
    revisionList: object[]
    prefetchPOError: string
    purchaseOrder: object,
    purchaseOrders: object[]
    uploadBuildError: string
    uploadBuildSuccess: string,
}

const initialState = {
    addEntitlementsError: '',
    addEntitlementsSuccess: '',
    boardResult: {},
    boardSearchError: '',
    chipTypes: [],
    currentEntitlements: [],
    entitlements: [],
    getCurrentEntitlementsListError: '',
    getEntitlementsListError: '',
    getProductListError: '',
    getPOError: '',
    getRevisionListError: '',
    isLoading: false,
    loggedIn: false,
    loginError: '',
    pbuldList: [],
    products: [],
    prefetchPOError: '',
    purchaseOrder: {},
    purchaseOrders: [],
    revisionList: [],
    uploadBuildError: '',
    uploadBuildSuccess: '',
}

function rootReducer(state: StateShape = initialState, action: any): any {
    switch (action.type) {
    // Add Entitlement
    case ADD_ENTITLEMENTS_ERROR:
        return {
            ...state,
            addEntitlementsError: action.payload,
            addEntitlementsSuccess: '',
        };
    case ADD_ENTITLEMENTS_SUCCESS:
        return {
            ...state,
            addEntitlementsError: '',
            addEntitlementsSuccess: action.payload,
        };
        // Board Search
    case BOARDSEARCH_ERROR:
        return {
            ...state,
            boardSearchError: action.payload,
            boardResult: {},
        };
    case BOARDSEARCH_SUCCESS:
        return {
            ...state,
            boardResult: action.payload,
            boardSearchError: '',
        };
    case CLEAR_BOARD_SEARCH_RESULTS:
        return {
            ...state,
            boardResult: null,
            boardSearchError: '',
        };
        // Chip Types
    case GET_CHIP_LIST_ERROR:
        return {
            ...state,
            getChipListError: action.payload,
            chipTypes: [],
        };
    case GET_CHIP_LIST_SUCCESS:
        return {
            ...state,
            chipTypes: action.payload,
            getChipListError: '',
        };
        // Get Current Entitlements
    case GET_CURRENT_ENTITLEMENTS_LIST_ERROR:
        return {
            ...state,
            currentEntitlements: [],
            getCurrentEntitlementsListError: action.payload,
            getCurrentEntitlementsListSuccess: '',
        };
    case GET_CURRENT_ENTITLEMENTS_LIST_SUCCESS:
        return {
            ...state,
            currentEntitlements: action.payload,
            getCurrentEntitlementsListError: '',
            getCurrentEntitlementsListSuccess: true,
        };
        // Entitlements
    case GET_ENTITLEMENTS_LIST_ERROR:
        return {
            ...state,
            getEntitlementsListError: action.payload,
            entitlements: [],
        };
    case GET_ENTITLEMENTS_LIST_SUCCESS:
        return {
            ...state,
            entitlements: action.payload,
            getEntitlementsListError: '',
        };
    case CLEAR_ADD_ENTITLEMENTS_SUCCESS:
        return {
            ...state,
            addEntitlementsSuccess: '',
        };
    case CLEAR_ENTITLEMENTS_RESULTS:
        return {
            ...state,
            entitlements: [],
            addEntitlementsError: null,
            addEntitlementsSuccess: null,
            getCurrentEntitlementsListError: '',
            getCurrentEntitlementsListSuccess: '',
        };
        // PBULD
    case GET_PBULD_LIST_ERROR:
        return {
            ...state,
            getPbuldListError: action.payload,
            pbuldList: [],
        };
    case GET_PBULD_LIST_SUCCESS:
        return {
            ...state,
            pbuldList: action.payload,
            getPbuldListError: '',
        };
        // Product
    case GET_PRODUCT_LIST_ERROR:
        return {
            ...state,
            getProductListError: action.payload,
            products: [],
        };
    case GET_PRODUCT_LIST_SUCCESS:
        return {
            ...state,
            products: action.payload,
            getProductListError: '',
        };
        // Revision
    case GET_REVISION_LIST_ERROR:
        return {
            ...state,
            getRevisionListError: action.payload,
            revisionList: [],
        };
    case GET_REVISION_LIST_SUCCESS:
        return {
            ...state,
            revisionList: action.payload,
            getRevisionListError: '',
        };
        // Login
    case LOGIN_ERROR:
        return {
            ...state,
            loginError: action.payload,
        };
    case LOGIN_SUCCESS:
        return {
            ...state,
            loggedIn: true,
            loginError: '',
        };
    case LOGOUT:
        return {
            ...initialState,
            loginError: action.payload,
        };
    case SET_IS_LOADING:
        return {
            ...state,
            isLoading: action.payload,
        };
        // Prefetch Aligni PO's
    case PREFETCH_PO_LIST_ERROR:
        return {
            ...state,
            prefetchPOError: action.payload,
            purchaseOrders: [],
        };
    case PREFETCH_PO_LIST_SUCCESS:
        return {
            ...state,
            purchaseOrders: action.payload,
            prefetchPOError: '',
        };
        // Get Specified Purchase Order
    case GET_PO_ERROR:
        return {
            ...state,
            getPurchaseOrderError: action.payload,
            purchaseOrder: {},
        };
    case GET_PO_SUCCESS:
        return {
            ...state,
            purchaseOrder: action.payload,
            getPurchaseOrderError: '',
            saveLotNumberError: '',
            saveLotNumberSuccess: '',
        };
        // Save Lot Number to Database
    case SAVE_LOT_NUMBER_ERROR:
        return {
            ...state,
            saveLotNumberError: action.payload,
            saveLotNumberSuccess: '',
        };
    case SAVE_LOT_NUMBER_SUCCESS:
        return {
            ...state,
            saveLotNumberError: '',
            saveLotNumberSuccess: 'Successfully saved',
        };
    case CLEAR_LOT_NUMBER_RESULTS:
        return {
            ...state,
            purchaseOrder: {},
            getPurchaseOrderError: '',
            saveLotNumberError: null,
            saveLotNumberSuccess: null,
        };
        // Upload build
    case UPLOAD_BUILD_ERROR:
        return {
            ...state,
            uploadBuildError: action.payload,
            uploadBuildSuccess: '',
        };
    case UPLOAD_BUILD_SUCCESS:
        return {
            ...state,
            uploadBuildError: '',
            uploadBuildSuccess: action.payload,
        };
    default:
        return state;
    }
}

export default rootReducer
