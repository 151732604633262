export const ACCESS_TOKEN_NAME = 'ACCESS_TOKEN_NAME'
export const REFRESH_TOKEN_NAME = 'REFRESH_TOKEN_NAME'

export const BOARDSEARCH_ERROR = 'BOARDSEARCH_ERROR'
export const BOARDSEARCH_SUCCESS = 'BOARDSEARCH_SUCCESS'
export const CLEAR_BOARD_SEARCH_RESULTS = 'CLEAR_BOARD_SEARCH_RESULTS';

export const ADD_ENTITLEMENTS_ERROR = 'ADD_ENTITLEMENTS_ERROR'
export const ADD_ENTITLEMENTS_SUCCESS = 'ADD_ENTITLEMENTS_SUCCESS'
export const CLEAR_ADD_ENTITLEMENTS_SUCCESS = 'CLEAR_ENTITLEMENTS_LIST_SUCCESS'
export const CLEAR_ENTITLEMENTS_RESULTS = 'CLEAR_ENTITLEMENTS_RESULTS';

export const GET_CHIP_LIST_ERROR = 'GET_CHIP_LIST_ERROR'
export const GET_CHIP_LIST_SUCCESS = 'GET_CHIP_LIST_SUCCESS'

export const GET_CURRENT_ENTITLEMENTS_LIST_ERROR = 'GET_CURRENT_ENTITLEMENTS_LIST_ERROR'
export const GET_CURRENT_ENTITLEMENTS_LIST_SUCCESS = 'GET_CURRENT_ENTITLEMENTS_LIST_SUCCESS'

export const GET_ENTITLEMENTS_LIST_ERROR = 'GET_ENTITLEMENTS_LIST_ERROR'
export const GET_ENTITLEMENTS_LIST_SUCCESS = 'GET_ENTITLEMENTS_LIST_SUCCESS'

export const GET_PBULD_LIST_ERROR = 'GET_PBULD_LIST_ERROR'
export const GET_PBULD_LIST_SUCCESS = 'GET_PBULD_LIST_SUCCESS'

export const GET_PO_SUCCESS = 'GET_PO_SUCCESS'
export const GET_PO_ERROR = 'GET_PO_ERROR'

export const GET_PRODUCT_LIST_ERROR = 'GET_PRODUCT_LIST_ERROR'
export const GET_PRODUCT_LIST_SUCCESS = 'GET_PRODUCT_LIST_SUCCESS'

export const GET_REVISION_LIST_ERROR = 'GET_REVISION_LIST_ERROR'
export const GET_REVISION_LIST_SUCCESS = 'GET_REVISION_LIST_SUCCESS'

export const LOGIN_ERROR = 'LOGIN_ERROR'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGOUT = 'LOGOUT'

export const PREFETCH_PO_LIST_ERROR = 'PREFETCH_PO_LIST_ERROR'
export const PREFETCH_PO_LIST_SUCCESS = 'PREFETCH_PO_LIST_SUCCESS'

export const SAVE_LOT_NUMBER_ERROR = 'SAVE_LOT_NUMBER_ERROR'
export const SAVE_LOT_NUMBER_SUCCESS = 'SAVE_LOT_NUMBER_SUCCESS'
export const CLEAR_LOT_NUMBER_RESULTS = 'CLEAR_LOT_NUMBER_RESULTS';

export const SET_IS_LOADING = 'SET_IS_LOADING'

export const UPLOAD_BUILD_ERROR = 'UPLOAD_BUILD_ERROR'
export const UPLOAD_BUILD_SUCCESS = 'UPLOAD_BUILD_SUCCESS'
