import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
    useHistory, withRouter, RouteComponentProps,
} from 'react-router-dom';
import { boardSearch } from '../actions/index';
import formStyles from '../styles/form.module.css';
import sectionStyles from '../styles/section.module.css';
import boardSearchStyles from '../styles/boardSearch.module.css';

interface BoardSearchFormProps extends RouteComponentProps {
    searchTerm: string;
    boardSearch: (state: { searchTerm: string }) => void;
    boardResult?: object | null;
    boardSearchError?: string | null;
}

const BoardSearchForm: React.FC<BoardSearchFormProps> = ({
    searchTerm: initialSearchTerm, boardSearch, boardResult, boardSearchError,
}) => {
    const [searchTerm, setSearchTerm] = useState(initialSearchTerm || '');
    const history = useHistory();

    const executeSearch = (searchTerm: string) => {
        if (boardSearch) {
            boardSearch({ searchTerm });
        }
    };

    useEffect(() => {
        if (initialSearchTerm) {
            setSearchTerm(initialSearchTerm);
            executeSearch(initialSearchTerm);
        }
    }, [initialSearchTerm]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        const { value } = e.target;
        setSearchTerm(value);
    };

    const handleSubmit = (e: React.FormEvent<HTMLElement>): void => {
        e.preventDefault();
        executeSearch(searchTerm);
        history.push({
            pathname: history.location.pathname,
            search: `?searchTerm=${encodeURIComponent(searchTerm)}`,
        });
    };

    const renderForm = (): React.ReactElement => (
        <React.Fragment>
            <form className={formStyles.form} onSubmit={handleSubmit}>
                <h1 className={sectionStyles.header}>Board Search</h1>
                <label className={formStyles.component} htmlFor="searchTerm">Search:</label> {/* eslint-disable-line*/}
                <input
                    className={formStyles.component}
                    type="text"
                    name="searchTerm"
                    value={searchTerm}
                    onChange={handleChange}
                />
                <input
                    className={formStyles.submitButton}
                    value="Search"
                    type="submit"
                />
            </form>
            {renderError()}
            {renderResults()}
        </React.Fragment>
    );

    const renderError = (): React.ReactElement | null => {
        if (boardSearchError) {
            const errorMessage = boardSearchError;
            return (
                <div className={formStyles.error}>
                    {`${errorMessage}`}
                </div>
            );
        }
        return null;
    };

    const renderTestResults = (key: any, val: any) => {
        let idCounter = 0;
        return (
            <>
                <div key={`${key}-${idCounter}`}>
                    <span className={boardSearchStyles.resultItemLabel}>{`${key}:`}</span>
                    <br />
                    {Object.entries(val).map(([k, v]: any) => {
                        idCounter += 1;
                        return (
                            <div className={boardSearchStyles.resultItemContainer} key={`${key}-${idCounter}`}>
                                <span className={boardSearchStyles.resultItemLabel}>{`${k}:`}</span>
                                <br />
                                {highlightResult(v)}
                            </div>
                        );
                    })}
                </div>
                <br />
            </>
        );
    };

    const highlightResult = (val: string): React.ReactElement | null => {
        switch (val) {
        case 'Pass':
            return <span className={boardSearchStyles.success}>{`${val}`}</span>;
        case 'Fail':
        case 'Error':
        case 'Abort':
        case 'Cancel':
            return <span className={boardSearchStyles.error}>{`${val}`}</span>;
        default:
            return <span>{`${val}`}</span>;
        }
    };

    const renderResults = (): React.ReactElement | null => {
        if (boardResult === null || boardResult === undefined) {
            return null;
        }
        if (Object.keys(boardResult).length !== 0) {
            let columnCounter = 0;

            return (
                <div className={boardSearchStyles.resultContainer}>
                    {
                        Object.entries(boardResult).map(([key, value]: any) => {
                            key = key.charAt(0).toUpperCase() + key.slice(1);

                            let idCounter = 0;
                            columnCounter += 1;

                            if (Object.keys(value).length === 0) {
                                return null;
                            }

                            return (
                                <div
                                    className={boardSearchStyles.resultSubContainer}
                                    style={{ gridColumnStart: `${columnCounter}` }}
                                    key={`${key}-container`}
                                >
                                    <h2 key={key}>
                                        {`${key}`}
                                    </h2>
                                    {
                                        Object.entries(value).map(([k, val]: any) => {
                                            let updatedValue = val;
                                            idCounter += 1;
                                            if (k.includes('Result')) {
                                                return renderTestResults(k, val);
                                            }
                                            if (Array.isArray(val)) {
                                                updatedValue = val.map((item: string, index: number) => (
                                                    <React.Fragment key={index}>
                                                        <span>{item}</span>
                                                        {index !== val.length - 1 && <br />}
                                                    </React.Fragment>
                                                ));
                                            }
                                            return (
                                                <div className={boardSearchStyles.resultItemContainer} key={idCounter}>
                                                    <span className={boardSearchStyles.resultItemLabel}>{`${k}:`}</span>
                                                    <br />
                                                    {Array.isArray(updatedValue) ? updatedValue : <span>{`${updatedValue}`}</span>}
                                                </div>
                                            );
                                        })
                                    }
                                </div>
                            );
                        })
                    }
                </div>
            );
        }
        return null;
    };

    return renderForm();
};

const mapStateToProps = (state: any): object => (
    {
        boardResult: state.boardResult,
        boardSearchError: state.boardSearchError,
    }
);

const mapDispatchToProps = (dispatch: Function): object => (
    {
        boardSearch: (state: { searchTerm: string }): void => dispatch(boardSearch(state)),
    }
);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BoardSearchForm));
