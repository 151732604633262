import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Header from '../components/shared/Header';
import BoardSearchForm from '../components/BoardSearchForm';
import { boardSearch, clearBoardSearchResults } from '../actions/index';

const useQuery = () => new URLSearchParams(useLocation().search);

const BoardSearch = ({ boardSearch, clearBoardSearchResults }: { boardSearch: (state: { searchTerm: string }) => void, clearBoardSearchResults: () => void }): any => {
    const query = useQuery();
    const searchTerm = query.get('searchTerm') || '';

    useEffect(() => () => {
        clearBoardSearchResults();
    }, [clearBoardSearchResults]);

    return (
        <div>
            <Header />
            <BoardSearchForm searchTerm={searchTerm} boardSearch={boardSearch} />
        </div>
    );
};

const mapDispatchToProps = {
    boardSearch,
    clearBoardSearchResults,
};

export default connect(
    null,
    mapDispatchToProps,
)(BoardSearch);
